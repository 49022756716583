<template>
	<div class="notice_popup hr">
		<div class="close">
			<img src="/images/close.png" alt="닫기" @click="$emit('close')"/>
		</div>
		<div class="tit">하이프로 기술인재 평가 확정하기</div>
		<div class="evl-star">
			<SelectComp tagName="p" type="text" cdId="PRO110" v-model="row.evalItemDivCd" v-for="(row, rowIdx) in evalGradeList" :key="rowIdx" :class="rowIdx == 0 ? 'ivl13' : ''">
				<template v-slot="slot">{{slot.item.cdNm}} <GradeImg type="PRON_EVAL" :grade="row.pronGradeCd" /></template>
			</SelectComp>
		</div>
		<div class="member"><span class="name">{{mberInfo.mberNm}} ({{mberInfo.gender | gender}} / {{mberInfo.birthYyyymmdd | birthYear}}) 님에게</span><br/>하이프로 평가를 확정하시겠습니까? </div>
		<div class="select-btn">
			<div class="btn" @click="$emit('close')">취소</div>
			<div class="btn" @click="$emit('close', true)">예</div>
		</div>
	</div>
</template>

<script>
import GradeImg from '@/components/highpro/GradeImg.vue';

export default {
	props:{
		param : Object
	},
	data() {
		return {
			forwardData : this.param,
			tecMberSeq : this.param.tecMberSeq,
			gradeAry : this.param.gradeAry,
			
			mberInfo : {},
			evalGradeList : []
		}
	},

	components : {
		GradeImg
	},

	mounted() {
		//console.log('param', this.param);

		this.getPronEvalGradeData();
	},

	methods : {
		getPronEvalGradeData() {
			this.$.httpPost('/api/mem/adm/tec/getPronEvalGradeData', this.forwardData)
				.then(res => {
					//console.log('getPronEvalGradeData RESULT', res);

					this.mberInfo = res.data.mberInfo;
					this.evalGradeList = res.data.evalGradeList;
				}).catch(this.$.httpErrorCommon);
		}
	}
}
</script>